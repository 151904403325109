import { Injectable } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor() { }

  public noEmptySpacesValidator(control: FormControl): ValidationErrors | null {
    const isWhitespace = control.value && control.value.length > 0 && (control.value).trim().length === 0;
    const isValid = !isWhitespace;

    return isValid ? null : { 'whitespace': true };
  }
}
