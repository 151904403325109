<app-page-banner
  [title]="mainBannerInfo.title ? mainBannerInfo.title : ''"
  [description]="mainBannerInfo.description ? mainBannerInfo.description : ''"
  [backgroundImageUrl]="mainBannerInfo.backgroundImageUrl ? mainBannerInfo.backgroundImageUrl : ''"
  [imageUrl]="mainBannerInfo.itemImageUrl ? mainBannerInfo.itemImageUrl : ''"
  [useGradient]="mainBannerInfo.useGradient"
  [pseudoElementColor]="environment.brandStyleVariables.pseudoElementHighlightColor"
  [smallBanner]="(environment.showUserPointsInBanners && (generalService.isUserLoggedIn | async)) ? false : true"
  [textContainerMaxWidth]="'70%'"
  [imageSize]="'35%'">
</app-page-banner>

<div class="activity-page-wrapper">
  <p class="activity-info-title mt-4">{{ labelService.activityDetailsScreenTitle | label }}</p>

  <div class="activity-info-wrapper mb-4" *ngIf="isLoaded" [@FadeIn]="animationState">
    <div class="activity-info-card card-radius">
      <app-item-image-slider
        [imageList]="activity.images ? activity.images : []"
        [paginationBulletColor]="environment.brandStyleVariables.pseudoElementHighlightColor">
      </app-item-image-slider>

      <app-activity-details-card [activity]="activity" (triggerGetActivityInfo)="getActivityDetails()"></app-activity-details-card>
    </div>

    <div class="similar-activities-list-wrapper">
      <p class="activities-list-title">{{ labelService.activityDetailsRelated | label }}</p>

      <div class="similar-activities-list-container">
        <ng-container *ngFor="let activityCard of similarActivitiesList">
          <app-list-item-card class="activity-list-card"
            [image]="activityCard.images && activityCard.images.length > 0 ? activityCard.images[0].href : 'assets/imgs/environments/' + environment.tenantName + '/default-images/default-image-small-rectangle.svg'"
            [title]="activityCard.name" [highlighted]="activityCard.type === ActivityTypes.INPUT" [highlightBackgroundColor]="'var(--main-brand-color)'" [highlightFontColor]="'white'"
            [itemType]="activityCard.type" [itemTypeImage]="activityCard.type === ActivityTypes.INPUT ? 'assets/imgs/environments/' + environment.tenantName + '/activities/'+ (activityCard.type | lowercase) + '-activity-type-icon.png' : ''"
            [shadowBrandColor]="'rgba(0, 0, 0, 0.15)'" (click)="activityItemClicked(activityCard.activityId)" >
            <ng-template>
              <div class="w-100">
                <app-activity-list-item [activity]="activityCard"></app-activity-list-item>
              </div>
            </ng-template>
          </app-list-item-card>
        </ng-container>

        <ng-container *ngIf="isListLoading">
          <app-loading-list-item class="loading-item" [ngClass]="{'opacity-0': !isListLoading}" *ngFor="let item of loadingListItems">
            <ng-template>
              <app-activity-loading-content class="w-100"></app-activity-loading-content>
            </ng-template>
          </app-loading-list-item>
        </ng-container>
      </div>
    </div>
  </div>
</div>
