import { animate, state, style, transition, trigger } from '@angular/animations';
import { getCurrencySymbol } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { Currency } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { PopupService } from 'src/app/_services/popup-service.service';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { PaymentTypeEnum } from 'src/app/pages/marketplace/_services/_order-entry/order-entry.model';
import { OrderEntryService } from 'src/app/pages/marketplace/_services/_order-entry/order-entry.service';
import { Product } from 'src/app/pages/marketplace/_services/_products/products.service.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-purchase-popup',
  templateUrl: './purchase-popup.component.html',
  styleUrls: ['./purchase-popup.component.sass'],
  animations: [
    trigger('ZoomIn', [
      state('hide', style({
        opacity: '0',
        transform: 'translate(-50%, -50%) scale(0.75, 0.75)'
      })),
      state('show', style({
        opacity: '1',
        transform: 'translate(-50%, -50%) scale(1, 1)'
      })),
      transition('show => hide',  animate('300ms ease-in-out')),
      transition('hide => show',  animate('300ms ease-in-out'))
    ]),
    trigger('BackdropFadeIn', [
      state('hide', style({
        opacity: '0',
      })),
      state('show', style({
        opacity: '0.32',
      })),
      transition('hide <=> show',  animate('300ms ease-in-out')),
    ]),
  ]
})
export class PurchasePopupComponent implements OnInit, AfterViewInit {

  @Input() enableXButton: boolean = true;
  @Input() closeClickOutside: boolean = false;
  @Input() product: Product;
  @Input() paymentType: PaymentTypeEnum;

  @Output() buttonClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() popUpClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  public animationState: string = 'hide';

  public selectedQuantity: number = 0;

  public getCurrencySymbol = getCurrencySymbol;

  public showErrorMessage: boolean = false;
  public errorMessageToShow: string = '';

  public environment = environment;

  public currency: Currency;

  constructor(
    public translateService: TranslateService,
    public labelService: LabelsService,
    private labelPipe: LabelPipe,
    private popupService: PopupService,
    public generalService: GeneralService,
    private orderEntryService: OrderEntryService
  ) { }

  ngOnInit(): void {
    this.changeQuantity(1);

    const currencyAux: Currency | undefined = this.generalService.generalInfo.brandingInfo.currencies?.find(c => c.type === this.product.prices[0].unit);
    this.currency = currencyAux ? currencyAux : {
      type: this.product.prices[0].unit,
      name: this.product.prices[0].unit,
      requiresWallet: true,
      paymentTypes: []
    };
  }

  ngAfterViewInit(): void {
    (async () => {
      await new Promise<void>(resolve => setTimeout(() => resolve(), 0)).then(() => {
        this.animationState = 'show';
    })})();
  }

  public closePopUp(): void {
    this.animationState = 'hide';
    (async () => {
      await new Promise<void>(resolve => setTimeout(() => resolve(), 300)).then(() => {
        this.popUpClosed.emit(true);
    })})();
  }

  public changeQuantity(increment: number): void {
    if (increment < 0 || this.selectedQuantity < this.product.quantity!) {
      this.selectedQuantity = Math.max(1, this.selectedQuantity + increment);

      /* if (this.selectedQuantity > 2) {
        // TODO get product limit per user and user already purchased amount
        this.showErrorMessage = true;
        this.errorMessageToShow = this.labelPipe.transform(this.labelService.purchaseProductPopupLimitReachedErrorMessage, { quantity: voucherLimitPerUser - userPurchasedVouchers } );

      } else if (this.selectedQuantity > 3) {
        // TODO get product limit per user
        this.showErrorMessage = true;
        this.errorMessageToShow = this.labelPipe.transform(this.labelService.purchaseProductPopupLimitAmountExceededErrorMessage);

      } else if (this.selectedQuantity * this.product.prices[0].amount > this.generalService.userDefaultWallet.currentValue) {
        // TODO get points from user details
        this.showErrorMessage = true;
        this.errorMessageToShow = this.labelPipe.transform(this.labelService.purchaseProductPopupInsuficientFundsErrorMessage);
      } else {
        this.showErrorMessage = false;
        this.errorMessageToShow = '';
      } */

      // TODO delete
      if (this.selectedQuantity * this.product.prices[0].amount > this.generalService.userDefaultWallet.currentValue) {
        this.showErrorMessage = true;
        this.errorMessageToShow = this.labelPipe.transform(this.labelService.purchaseProductPopupInsuficientFundsErrorMessage);
      } else {
        this.showErrorMessage = false;
        this.errorMessageToShow = '';
      }
    }
  }

  public purchaseConfirm(): void {
    this.closePopUp();

    this.orderEntryService.createOrderEntry(this.selectedQuantity, this.product.id, this.paymentType).pipe(take(1)).subscribe(
      {
        next: () => {
          this.generalService.getUserDetails().pipe(take(1)).subscribe(() => {
            this.openSuccessPopup();
          });
        }
      }
    );
  }

  private openSuccessPopup(): void {
    const successTitle = this.labelPipe.transform(this.labelService.productPurchaseSuccessPopupTitle);
    const quantityLabel = this.selectedQuantity > 1 ? this.labelPipe.transform(this.labelService.productPurchaseSuccessPopupPluralQuantityLabel, { quantity: this.selectedQuantity }) : this.labelPipe.transform(this.labelService.productPurchaseSuccessPopupQuantityLabel);
    // const dateLabel = this.labelPipe.transform(this.labelService.productDetailsDate);
    const buttonLabel = this.labelPipe.transform(this.labelService.productPurchaseSuccessPopupButtonLabel);

    this.popupService.setPopupToOpen(
      { text: '' },
      'assets/imgs/environments/' + environment.tenantName + '/popup-success-image.png',
      {
        text: successTitle,
        style: {
          fontSize: 'var(--main-info-font-size)'
        }
      },
      [
        {
          text: quantityLabel,
          style: {
            fontSize: 'var(--main-info-font-size)',
            fontColor: '#757575',
            textAlign: 'left',
            fontWeight: '400',
            marginBottom: '0'
          }
        },
        {
          text: this.product.name,
          style: {
            fontSize: 'var(--main-info-font-size)',
            fontColor: 'var(--main-brand-color)',
            textAlign: 'left',
            fontWeight: '700',
            marginTop: '0px !important'
          }
        },
        /* {
          // TODO GET EXPIRATION DATE OF PURCHASED VOUCHER
          text: dateLabel + new DatePipe(this.product.endDate!, 'dd MMMM, yyyy'),
          style: {
            // TODO
            textAlign: 'left'
          }
        } */
      ],
      [{
        text: buttonLabel,
        isCloseBtn: true,
        actionValue: this.product.id,
        actionText: 'voucherHistory',
        style: {
          backgroundColor: 'var(--main-brand-color)',
          fontColor: '#ffffff'
        }
      }],
      false,
      true
    );
  }
}
