export const environment = {
  showVersion: true,
  production: false,

  defaultLanguage: 'pt',
  showMockDataPopup: false,
  apiBaseUrl: 'https://qa.api.gamifyou.com/',
  translation_files_url: '',

  tenantName: 'YOUR',
  tenantId: '0cf362c2-7bff-4cdf-a1b2-038cf9264f87',
  applicationId: 'b5bb4c5b-a986-4cf0-b9c5-2e26e110e9b8',


  // General Application Information
  pageTitle: '[QA] YOUR',

  //Wallet Information
  useWallet: false,
  web3ClientId: 'BKloJT7k_Q-GKTVTmsZQIez9fT9-Zu6FjpL6jxYFQnxysBPzG74awHIucT89K0pAj8yWXj0L8nfm5NBK8v2dIDY',


  // Keycloak configurations
  useKeycloak: true,
  keycloakConfig: {
    url: 'https://qa.auth.gamifyou.com/',
    realm: 'YOUR',
    clientId: 'your'
  },

  // disable scroll animation
  useScrollAnimation: false,

  // custom loading animation
  customLoading: true,
  customLoadingAnimationType: 'rive',

  // CSS Variables
  brandStyleVariables: {
    brandDarkerColor: '#532547',
    brandLighterColor: '#239C95',
    highLightPrimaryColor: '#852C41',
    highLightSecondaryColor: '#532547',
    mainBrandColor: '#ED0677',
    mainBrandColorLight: '#ED067766',
    mainBrandColorLighter: '#ED067733',
    mainBrandColorLightest: '#ED06771A',
    pseudoElementHighlightColor: '#ED0677',
    mainErrorColor: '#FF3E3E',
    mainWarningColor: '#FFB200',
    mainBackgroundColor: 'white',
    mainFontColor: '#333333',
    secondaryFontColor: '#8692A6',
    mainBorderColor: '#8692A6',
    primaryGradient: 'linear-gradient(179.15deg, #DB4C71 18.53%, #532547 97.89%)',
    secondaryGradient: 'linear-gradient(114.48deg, #DB4C71 12.15%, #532547 98.67%)',
    brandBackgroundColor: '#ED0677',
    inputBackgroundColor: '#f0f1f2',
    inputSecondaryBackgroundColor: '#fafbff',
    imageDisabledColor: '#00000066',
    onboardingStepCardBackground: '#fff6f6',
    footerBackgroundColor: '#FFFFFF',
    footerTextColor: '#000000',
    footerButtonsColor: '#343538',
    listShadowBrandColor: 'rgba(237, 6, 119, 0.4)'
  },

  firebaseConfig: {
    apiKey: "AIzaSyDsDdt6m24he2N1hUQ7LK-dp1NsJ5LxOh8",
    authDomain: "givin-89ac1.firebaseapp.com",
    projectId: "givin-89ac1",
    storageBucket: "givin-89ac1.appspot.com",
    messagingSenderId: "356995805010",
    appId: "1:356995805010:web:05a64627bd9e5b3265548b",
    measurementId: "G-QGFWLM9MM2"
  },

  homeRequiredRoles: [],

  // Contact Us
  contactUsFormAlignLeft: false,
  contactUsFormBackgroundImage: 'assets/mock/environments/YOUR/contact-us/contact_us_form_background.png',
  contactUsFormEnvironmentIcon: 'assets/mock/environments/YOUR/contact-us/contact_us_form_environment_icon.png',
  showPhoneInput: true,

  // Footer
  showTermsAndConditions: true,
  showPrivacyPolicy: true,

  // User Info
  showUserPointsInBanners: true,

  // ############################################ Animations ############################################
  animations: {
    activityMultiStep: {
      type: 'IMAGE',
      url: 'assets/imgs/environments/' + 'YOUR' + '/animations/activity-multi-step-image.png'
    }
  },

  // ############################################ Redirect Routes ############################################
  routeRedirect: {
    landingPage: {
      shouldRedirectIfLoggedIn: true,
      pathToRedirect: 'home'
    }
    // TODO Add other routes if needed
  },

  // ############################################ Mocked Services ############################################
  allServicesMocked: false,
  useMockedData: {
    // ######## General ########
    generalGetInfo: true,
    generalGetLanguages: false,
    generalGetLPBanners: false,
    generalGetUserDetails: false,
    generalUpdateUserDetails: false,
    generalGetMenus: false,
    generalRegisterNewsletter: false,

    // ######## Labels ########
    labelsGetList: false,

    // about us
    aboutUsGetInfo: true,

    // blog
    blogGetFilters: true,

    // contact us
    contactUsSubmit: false,
    contactUsGetFAQS: true,

    //marketplace
    marketplaceGetTiers: false,
    marketplaceGetFilters: false,


    // marketplace - Order Entry
    marketplaceOrderEntryCreate: false,
    marketplaceOrderEntryGet: false,
    marketplaceOrderEntryGetList: false,

    // marketplace - products
    marketplaceProductsGet: false,
    marketplaceProductsGetList: false,
    marketplaceProductsGetRelatedList: false,

    // onboarding
    onboardingGetList: false,
    onboardingGetStep: false,
    onboardingSubmitStep: false,

    // our products
    ourProductsGetList: true,

    // partners
    partnersGetList: false,
    partnersGetCategories: false,
    partnersGetDetails: true,

    // profile
    profileGetTransactionsListHeaders: true,

    // terms & Conditions
    termsConditionsGet: true,

    // tutorial
    tutorialGetInfo: false,

    // user goals
    userGoalGetOptionsList: true,

    // history
    historyGetProductsList: false,
    historyGetTransactionList: false,

    // home
    homeActivityHistoryGetList: false,
    homeActivityPotencialRewards: false,

    // activities
    activitiesGetList: false,
    activitiesGet: false,
    activitiesInputAddUserInput: false,
    activitiesInputEditUserInput: false,
    activitiesInputDeleteUserInput: false,
  }
};
