<div class="w-100 d-flex flex-column mb-3">
  <div class="add-input-button align-self-end mb-2">
    <app-generic-button
      *ngIf="activity.canWinRewards && activity.inputs && activity.inputs.length > 0"
      [buttonName]="labelService.activityAddInputBtnLabel | label" [borderRadius]="'50px'"
      [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
      [brandColor]="'var(--main-brand-color)'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--main-info-font-size)'"
      [buttonSpacing]="'calc(var(--main-info-font-size) / 1.8) calc(var(--main-info-font-size) * 1.4)'"
      [hasIcon]="true" [iconClass]="'bi bi-plus'" [iconColor]="'white'" [iconSize]="'calc(var(--main-info-font-size) * 2.5)'"
      [fontWeight]="'700'" (click)="openInputPopup()">
    </app-generic-button>
  </div>

  <div class="previous-inputs-container" *ngIf="activity.userInputs">
    <app-list-header [fullSize]="!(generalService.isMobile | async)" [fontSize]="'var(--smallest-font-size)'" [fontColor]="'#8692A6'"
      [headerList]="userInputsListItemHeader" [showArrows]="false" [clickable]="false"></app-list-header>
    <div class="list-container">
      <app-list-item *ngFor="let userInput of userInputsList; let index = index;"
        [item]="userInput" [itemMargin]="'1vw 0'" [fullSize]="!(generalService.isMobile | async)"
        (actionEvent)="actionClicked(index, $event)"></app-list-item>
    </div>
  </div>
</div>

<app-activity-input-form-popup *ngIf="showInputPopup"
  [form]="form" (popUpClosed)="closeInputPopup()"
  (formSubmited)="formSubmited($event)" (userInputRemoved)="userInputTriggerRemoveConfirmation($event)">
</app-activity-input-form-popup>

<div class="popup-container" *ngIf="popupToShow">
  <app-generic-popup
    [closeClickOutside]="popupToShow.closeClickOutside!"
    [preImgTitle]="popupToShow.preImgTitle!"
    [image]="popupToShow.image!"
    [title]="popupToShow.title!"
    [descriptions]="popupToShow.descriptions!"
    [buttons]="popupToShow.buttons!"
    [enableXButton]="popupToShow.enableXButton"
    (popUpClosed)="popupClosed()"
    (buttonClicked)="popupActionClicked($event)">
  </app-generic-popup>
</div>
