<app-page-banner
  [title]="mainBannerInfo.title ? mainBannerInfo.title : ''"
  [description]="mainBannerInfo.description ? mainBannerInfo.description : ''"
  [backgroundImageUrl]="mainBannerInfo.backgroundImageUrl ? mainBannerInfo.backgroundImageUrl : ''"
  [imageUrl]="mainBannerInfo.itemImageUrl ? mainBannerInfo.itemImageUrl : ''"
  [useGradient]="mainBannerInfo.useGradient"
  [pseudoElementColor]="environment.brandStyleVariables.pseudoElementHighlightColor"
  [containerHeight]="(environment.showUserPointsInBanners && (generalService.isUserLoggedIn | async)) ? undefined : '36vh'"
  [textContainerMaxWidth]="'70%'"
  [imageSize]="'35%'">
</app-page-banner>

<div class="content-wrapper">
  <div class="submenu-area-container mb-5" *ngIf="subMenuList && subMenuList.length > 0">
    <ng-container *ngFor="let submenu of subMenuList">
      <a class="submenu-item" [routerLink]="currentMenu.viewUrl + submenu.viewUrl" [routerLinkActive]="'active'">
        <span>{{submenu.name}}</span>
      </a>
    </ng-container>
  </div>

  <router-outlet></router-outlet>
</div>
