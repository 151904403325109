<div class="product-info mt-4 mb-5">
  <div class="product-info-title-container">
    <span>{{ product.name }}</span>
  </div>

  <div class="product-info-table">
    <div class="product-detail-container">
      <p class="info-table-header">{{labelService.productDetailsInfoTitle | label}}</p>

      <div class="quantity-container" *ngIf="product.quantity && product.quantity > 0 && !isHistory">
        <div class="value" [ngClass]="{'is-blocked': isBlocked}">
          <img [src]="'assets/imgs/lock-icon.png'" class="me-2" *ngIf="isBlocked"> {{ (product.quantity | i18nPlural: generalService.getPluralKey('product_quantity')) | translate: {n: product.quantity} }}
        </div>
      </div>

      <div class="quantity-container" *ngIf="product.totalPurchased && product.totalPurchased > 0 && isHistory">
        <div class="value" [ngClass]="{'is-blocked': isBlocked}">
          <img [src]="'assets/imgs/lock-icon.png'" class="me-2" *ngIf="isBlocked"> {{ (product.totalPurchased | i18nPlural: generalService.getPluralKey('product_purchased')) | translate: {n: product.totalPurchased} }}
        </div>
      </div>

      <div class="date-container" *ngIf="product.endDate && !isHistory">
        {{ (labelService.productDetailsDate | label) + (product.endDate | date: 'dd MMMM, yyyy': translateService.currentLang: translateService.currentLang | titlecase)}}
      </div>
    </div>

    <div class="product-price-container">
      <div class="product-price">
        {{ 'marketplace_listitem_price' | label: { price: product.prices[0].amount, unit: getCurrencySymbol(currency.type, 'narrow') | label } | lowercase }}
      </div>
    </div>
  </div>

  <div class="product-info-table mt-5" *ngIf="partner || (product.locations && product.locations.length > 0)">
    <div class="addresses-container" *ngIf="product.locations && product.locations.length > 0">
      <swiper #swiperRef class="item-address-info-swiper"
        [slidesPerView]="1"
        [centeredSlides]="true"
        [pagination]="product.locations && product.locations.length > 1? {dynamicBullets: true, dynamicMainBullets: 3} : false"
        [navigation]="false"
        [spaceBetween]="20"
        [speed]="500"
        >

        <ng-template swiperSlide class="item-info-item-container"
          *ngFor="let location of product.locations; let index = index">
          <img [src]="'assets/imgs/environments/' + environment.tenantName + '/icons/maps-pin-icon.png'">

          <div class="address-info ms-2">
            <p class="address-line">{{ (location.addressLine1 ? location.addressLine1 + ', ' : '') + (location.addressLine2 ? location.addressLine2 + ', ' : '') + location.city }}</p>
            <p class="user-distance-from-address" *ngIf="location.distance">{{ labelService.productDetailsUserDistance | label: { distanceKm : location.distance / 1000 | number : '1.2-2' } }}</p>

            <a target="_blank" class="maps-redirect"
              href="https://www.google.pt/maps/search/?api=1&query={{location.gpsCoords!.x}}%2C{{location.gpsCoords!.y}}">
              {{ labelService.productDetailsSeeAddress | label }} <i class="bi bi-arrow-right-short"></i>
            </a>
          </div>
        </ng-template>
      </swiper>
    </div>

    <div class="partner-container" *ngIf="partner">
      <p class="info-table-header">{{labelService.productDetailsInstitution | label}}</p>

      <div class="partner-info-container">
        <img [src]="partner.image" [alt]="partner.name" class="partner-img">
        <div class="partner-name">{{ partner.name }}</div>
      </div>
    </div>
  </div>

  <div class="redeem-steps mt-5" *ngIf="isHistory && product.redeemSteps && product.redeemSteps.length > 0">
    <div class="step-container">
      <div class="step" *ngFor="let step of product.redeemSteps; let index = index">
        <div class="step-index-container" [ngClass]="{ 'only-step': product.redeemSteps.length === 1 }">
          {{ index + 1 }}
        </div>

        <p class="step-description">
          {{ step.description }}
        </p>
      </div>
    </div>
    <div class="animation-container">
      <!-- Rive -->
      <div class="rive-animation-container">
        <app-rive-animation-component
          [loop]="true"
          [animationFileUrl]="'assets/imgs/environments/' + environment.tenantName + '/products/redeem-steps-animation.riv'">
        </app-rive-animation-component>
      </div>
    </div>
  </div>

  <div class="product-info-text w-100 mt-5" *ngIf="product.description">
    <span class="title" *ngIf="labelService.productDetailsDescription | label">{{ labelService.productDetailsDescription | label }}</span>
    <div class="description">{{ product.description }}</div>
  </div>

  <div class="product-properties-wrapper w-100 mt-4" *ngIf="product.traits && product.traits.length > 0">
    <p class="title">{{labelService.productDetailsProperties | label}}</p>

    <div class="properties-list-container">
      <div class="property-container" *ngFor="let property of product.traits">
        <span class="property-name">{{ property.name }}</span>
        <br>
        <span class="property-value">{{ property.value }}</span>
      </div>
    </div>
  </div>

  <!-- <div class="product-tags w-100 mt-4" *ngIf="product.categories">
    <p class="title">Tags</p>
    <span class="tags-container" *ngFor="let tag of product.categories">
      {{ tag.name }}
    </span>
  </div> -->
</div>

<div class="product-info-button-container" *ngIf="!isHistory && product.quantity! > 0">
  <app-generic-button *ngIf="!isBlocked; else blockedBtn" [buttonName]="labelService.productDetailsBuyButton | label" [borderRadius]="'50px'"
    [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
    [brandColor]="'var(--main-brand-color)'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--main-info-font-size)'"
    [buttonSpacing]="'calc(var(--main-info-font-size) * 1.2) calc(var(--main-info-font-size) * 2)'"
    [fontWeight]="'700'" (click)="openPurchasePopup()">
  </app-generic-button>

  <ng-template #blockedBtn>
    <app-generic-button [buttonName]="labelService.productDetailsRedirectToMissionsButton | label : { currency : getCurrencySymbol(currency.type, 'narrow') | label }" [borderRadius]="'50px'"
      [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
      [brandColor]="'var(--main-brand-color)'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--main-info-font-size)'"
      [buttonSpacing]="'calc(var(--main-info-font-size) * 1.2) calc(var(--main-info-font-size) * 2)'"
      [fontWeight]="'700'" (click)="redirectToMission()">
    </app-generic-button>
  </ng-template>
</div>

<app-purchase-popup *ngIf="showPurchasePopup"
  [product]="product"
  [paymentType]="selectedPaymentType"
  (popUpClosed)="showPurchasePopup = false">
</app-purchase-popup>

<!-- <div class="product-info-button-container" *ngIf="product.quantity! > 0">
  <div class="purchase-button button-shadow" role="button" (click)="goToPaymentPage()">{{labelService.productDetailsBuyButton | label}}</div>
</div> -->
